// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acquista-js": () => import("./../../../src/pages/acquista.js" /* webpackChunkName: "component---src-pages-acquista-js" */),
  "component---src-pages-contatti-js": () => import("./../../../src/pages/contatti.js" /* webpackChunkName: "component---src-pages-contatti-js" */),
  "component---src-pages-grazie-js": () => import("./../../../src/pages/grazie.js" /* webpackChunkName: "component---src-pages-grazie-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ritiro-js": () => import("./../../../src/pages/ritiro.js" /* webpackChunkName: "component---src-pages-ritiro-js" */),
  "component---src-pages-scopri-js": () => import("./../../../src/pages/scopri.js" /* webpackChunkName: "component---src-pages-scopri-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */)
}

